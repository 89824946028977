
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  data() {
    return {
      endpoints: []
    };
  }, // data
  created() {
    this.checkAPI();
  }, // created
  methods: {
    ...mapActions('user', ['authenticate', 'clearSession']),
    ...mapActions('expectation', ['fetchExpectations']),
    ...mapActions('distribution', ['fetchDistributionByDate']),
    ...mapActions('distribution', ['fetchDistributionByPeriod']),
    ...mapActions('prediction', ['fetchTrafficPrediction']),
    ...mapActions('overview', ['fetchOverview']),
    ...mapActions('target', ['fetchTargets']),
    ...mapActions('comparison', ['fetchDistricts']),
    ...mapActions('comparison', ['fetchGroups']),
    ...mapActions('comparison', ['fetchSVs']),
    async checkAPI() {
      this.endpoints = [];

      await this.checkAuth();
      await this.checkOverview();
      await this.checkTabular();
      await this.checkRadar();
      await this.checkPrediction();
      await this.checkExpectation();
      await this.checkTarget();
      await this.checkComparisonByLocation();
      await this.checkComparisonByGroup();
      await this.checkComparisonBySV();
      await this.clearSession();
    }, // checkAPI
    async checkAuth() {
      const start = +new Date();

      const res = await this.authenticate({
        username: 'demo@flow-solutions.com',
        password: 'Insight2020'
      });

      const end = +new Date();

      this.endpoints.push({
        uri: '/oauth/token',
        status: 'SUCCESS',
        description: 'Success',
        time: end - start
      });
    }, // checkAuth
    async checkOverview() {
      const start = +new Date();
      const res = await this.fetchOverview({
        startDate: '2019-01-01',
        endDate: '2019-12-31',
        compareStartDate: '2018-01-01',
        compareEndDate: '2018-12-31',
        storeCodes: 'all',
        daysOfWeek: 'all'
      });

      const end = +new Date();

      this.endpoints.push({
        uri: 'kpis',
        status: res.status,
        description: res.description,
        time: end - start
      });
    }, // checkOverview
    async checkTarget() {
      const start = +new Date();

      const res = await this.fetchTargets({
        storeCodes: 'all',
        daysOfWeek: 'all'
      });

      const end = +new Date();

      this.endpoints.push({
        uri: 'kpi/target/sales',
        status: res.status,
        description: res.description,
        time: end - start
      });
    }, // checkTarget
    async checkTabular() {
      const start = +new Date();

      const res = await this.fetchDistributionByDate({
        startDate: '2019-01-01',
        endDate: '2019-12-31',
        storeCodes: 'all',
        daysOfWeek: 'all'
      });

      const end = +new Date();

      this.endpoints.push({
        uri: 'kpi/distribution/byDate',
        status: res.status,
        description: res.description,
        time: end - start
      });
    }, // checkTabular
    async checkRadar() {
      const start = +new Date();

      const res = await this.fetchDistributionByPeriod({
        startDate: '2019-01-01',
        endDate: '2019-12-31',
        compareStartDate: '2018-01-01',
        compareEndDate: '2018-12-31',
        storeCodes: 'all',
        daysOfWeek: 'all'
      });

      const end = +new Date();

      this.endpoints.push({
        uri: 'kpi/distribution/byPeriod',
        status: res.status,
        description: res.description,
        time: end - start
      });
    }, // checkRadar
    async checkPrediction() {
      const start = +new Date();

      const res = await this.fetchTrafficPrediction({
        storeCodes: 'all',
        daysOfWeek: 'all'
      });

      const end = +new Date();

      this.endpoints.push({
        uri: 'kpi/prediction/traffic',
        status: res.status,
        description: res.description,
        time: end - start
      });
    }, // checkPrediction
    async checkExpectation() {
      const start = +new Date();

      const res = await this.fetchExpectations();

      const end = +new Date();

      this.endpoints.push({
        uri: 'whatToExpect',
        status: res.status,
        description: res.description,
        time: end - start
      });
    }, // checkExpectation
    async checkComparisonByLocation() {
      const start = +new Date();

      const res = await this.fetchDistricts({
        startDate: '2019-01-01',
        endDate: '2019-12-31',
        storeCodes: 'all',
        daysOfWeek: 'all',
        locationLevel: 'REGION'
      });

      const end = +new Date();

      this.endpoints.push({
        uri: 'kpi/comparison/byLocation',
        status: res.status,
        description: res.description,
        time: end - start
      });
    }, // checkComparisonByLocation
    async checkComparisonByGroup() {
      const start = +new Date();

      const res = await this.fetchGroups({
        startDate: '2019-01-01',
        endDate: '2019-12-31',
        storeCodes: 'all',
        daysOfWeek: 'all'
      });

      const end = +new Date();

      this.endpoints.push({
        uri: 'kpi/comparison/byPG',
        status: res.status,
        description: res.description,
        time: end - start
      });
    }, // checkComparisonByGroup
    async checkComparisonBySV() {
      const start = +new Date();

      const res = await this.fetchSVs({
        startDate: '2019-01-01',
        endDate: '2019-12-31',
        storeCodes: 'all',
        daysOfWeek: 'all'
      });

      const end = +new Date();

      this.endpoints.push({
        uri: 'kpi/comparison/byAM',
        status: res.status,
        description: res.description,
        time: end - start
      });
    } // checkComparisonBySV
  } // methods
}; // export default
